import React from 'react';
import { IoSend } from 'react-icons/io5';
import InputEmojiWithRef from 'react-input-emoji';
import './TextBoxWithSend.css';
import { IoMdClose } from 'react-icons/io';

const TextBoxWithSend = ({ newComment, setNewComment, handleSend, activeCommentId, setActiveCommentId }) => {

    const handleTextChange = (e) => {
        setNewComment(e);
    };

    const handleSendClick = () => {
        if (newComment.trim()) {
            handleSend();
            setNewComment('');
            setActiveCommentId(null); // Exit reply mode after sending
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevents a new line from being added
            handleSendClick();
        }
    };

    const handleCancelClick = () => {
        setNewComment('');
        setActiveCommentId(null);
    };

    return (
        <div className="text-box-container" style={{display :'hidden'}}>
            <InputEmojiWithRef
                value={newComment}
                onChange={handleTextChange}
                onKeyDown={handleKeyPress} // Added this line to handle key press events
                cleanOnEnter
                height={20}
                maxLength={420}
                placeholder={activeCommentId ? "Type your reply..." : "Write a comment..."}
            />
            <IoSend
                className={`send-icon ${activeCommentId ? 'reply-active' : ''}`} // Conditionally apply the 'reply-active' class
                onClick={handleSendClick}
                disabled={!newComment.trim()}
            />
            {activeCommentId && (
                <IoMdClose
                    className="cancel-icon"
                    onClick={handleCancelClick}
                />
            )}
        </div>
    );
};

export default TextBoxWithSend;
