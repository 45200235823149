import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorPage from './Error';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("ErrorBoundary caught an error", error, errorInfo);
    // Navigate to error page
    this.props.navigate('/error');
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children; 
  }
}

const ErrorBoundaryWrapper = (props) => {
  const navigate = useNavigate();
  return <ErrorBoundary navigate={navigate} {...props} />;
};

export default ErrorBoundaryWrapper;
