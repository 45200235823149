import { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, startAfter, getDocs, doc, deleteDoc, getCountFromServer } from 'firebase/firestore';
import { db } from '../config/firebase-config';

const useFirestorePaginatedQuery = (collectionName, itemsPerPage) => {
  const [comments, setComments] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const fetchComments = async (page = 0) => {
    setLoading(true);
    let q = query(
      collection(db, collectionName),
      orderBy('createdAt'),
      limit(itemsPerPage)
    );

    if (page > 0 && lastVisible) {
      q = query(
        collection(db, collectionName),
        orderBy('createdAt'),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    }

    const querySnapshot = await getDocs(q);
    const newComments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setComments(newComments);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoading(false);

    if (page === 0) {
      const totalQuery = await getCountFromServer(collection(db, collectionName));
      setPageCount(Math.ceil(totalQuery.data().count / itemsPerPage));
    }
  };

  const deleteComment = async (id) => {
    await deleteDoc(doc(db, collectionName, id));
    fetchComments();
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return { comments, loading, pageCount, fetchComments, deleteComment };
};

export default useFirestorePaginatedQuery;
