import { formatDistanceToNow } from 'date-fns';


export const formatTimeAgo = (timestamp) => {
    const date = timestamp.toDate();
    return formatDistanceToNow(date, { addSuffix: true });
};

export const handleButtonClick = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };


 export const openCommentInNewWindow = (commentId,name) => {
    const newWindow = window.open(`/thread?url=${commentId}`, '_blank', 'noopener,noreferrer');
    if (newWindow) {
        newWindow.opener = null;
    }
};
