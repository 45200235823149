import React, { lazy, useState, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Profile from './component/Profile/Profile';
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';
import ThreadWindow from './component/ThreadWindow/ThreadWindow';
import Dashboard from './component/Dashboard/Dashboard';
import Navbar from './component/Navbar/Navbar';
import Notification from './component/Notification/Notification';
import Footer from './component/Footer/Footer';
import Right from './component/Rights/Right';
import ProtectedRoute from './common/ProtectedRoute';
import AdminRoute from './common/AdminRoute';
import Modal from 'react-modal';
import ErrorBoundaryWrapper from './component/Error/ErrorBoundary';
import ErrorPage from './component/Error/Error';
import LoadingScreen from './component/LoadingScreen/LoadingScreen';

function App() {
  Modal.setAppElement('#root');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const Profile = lazy(() => import('./component/Profile/Profile'));
  const Home = lazy(() => import('./component/Home/Home'));
  const ThreadWindow = lazy(() => import('./component/ThreadWindow/ThreadWindow'));
  const Dashboard = lazy(() => import('./component/Dashboard/Dashboard'));
  const Notification = lazy(() => import('./component/Notification/Notification'));
  const Login = lazy(() => import('./component/Login/Login'));
  const Verification = lazy(() => import('./component/Verification/Verification'));



  return (
    <Router>
      <ErrorBoundaryWrapper>
        <AuthProvider>
          <div className="app-container">
            <Navbar toggleSidebar={toggleSidebar} />
            <div className='app-main-content'>
              <Suspense fallback={<LoadingScreen />}>
                <Routes>
                  <Route path="/" element={<ProtectedRoute element={Home} />} />
                  <Route path="/home" element={<ProtectedRoute element={Home} />} />
                  <Route path="/thread" element={<ThreadWindow /> } />
                  <Route path="/notifications" element={<ProtectedRoute element={Notification} />} />
                  <Route path="/profile" element={<ProtectedRoute element={Profile} />} />
                  <Route path="/dashboard" element={<AdminRoute element={Dashboard} />} />
                  <Route path="/verify" element={<ProtectedRoute element={Verification} />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/error" element={<ErrorPage />} />
                  <Route path="*" element={<Navigate to="/home" />} />
                </Routes>
              </Suspense>
            </div>
            <Footer />
            <Right />
          </div>
        </AuthProvider>
      </ErrorBoundaryWrapper>
    </Router>
  );
}

export default App;
