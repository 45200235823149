import React, { useState, useEffect } from 'react';
import './Summary.css';
import { FaTrash } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import useCommentPaginatedQuery from '../../common/useCommentPaginatedQuery';
import useSpamPaginatedQuery from '../../common/useSpamPaginatedQuery';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import InsightsList from '../Insight/InsightsList';
import DeleteModal from '../DeleteModal/DeleteModal';
import { deleteCommentById } from '../../common/repository';

const Summary = ({ itemsPerPage = 6 }) => {
    const { comments, pageCount: commentPageCount, fetchComments } = useCommentPaginatedQuery('comments', itemsPerPage);
    const { spamReports, pageCount: spamPageCount, fetchSpamReports, deleteSpamReport } = useSpamPaginatedQuery('spamReports', itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const [view, setView] = useState('commentReport');
    const [threads, setThreads] = useState([]);
    const [popular, setPopular] = useState([]);
    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        fetchThreadsWithComments(5);
    }, []);

    useEffect(() => {
        if (view === 'commentReport') {
            fetchComments(currentPage);
        } else if (view === 'spam') {
            
            fetchSpamReports(currentPage);
        }
    }, [currentPage, view]);

    const fetchThreadsWithComments = async (limit) => {
        try {
            const q = query(collection(db, 'comments'), where('parentId', '==', null));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const commentsArray = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const commentsMap = new Map();
                commentsArray.forEach(comment => {
                    const url = comment.webpageUrl;
                    if (!commentsMap.has(url)) {
                        commentsMap.set(url, []);
                    }
                    commentsMap.get(url).push(comment);
                });

                const filteredComments = [];
                let count = 0;

                for (const [url, comments] of commentsMap) {
                    if (comments.length < 5) {
                        filteredComments.push({ url, count: comments.length, commentId: comments[0].id });
                        count++;
                        if (count >= limit) break;
                    }
                }

                const sortedComments = Array.from(commentsMap.entries())
                    .sort((a, b) => b[1].length - a[1].length)
                    .slice(0, 5)
                    .map(([url, comments]) => ({ url, count: comments.length, commentId: comments[0].id }));

                setPopular(sortedComments);
                setThreads(filteredComments);
            });

            return () => unsubscribe();
        } catch (error) {
            console.error("Error fetching threads with comments: ", error);
        }
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const handleDelete = async () => {
        try {
            setIsDeleteModalOpen(false);
            await deleteCommentById(selectedCommentId);
            fetchComments(currentPage);
            toast.success('Comment deleted successfully!', { autoClose: 1000 });
        } catch (error) {
            toast.error('Failed to delete comment.', { autoClose: 1000 });
        }
    };

    const handleSpamDelete = async () => {
        try {
            setIsDeleteModalOpen(false);
            await deleteCommentById(selectedCommentId);
            await deleteSpamReport(selectedCommentId);
            fetchComments(currentPage);
            fetchSpamReports(currentPage);
            toast.success('Comment deleted successfully!', { autoClose: 1000 });
        } catch (error) {
            toast.error('Failed to delete comment.', { autoClose: 1000 });
        }
    };

    const openDeleteModal = (commentId) => {
        setSelectedCommentId(commentId);
        setIsDeleteModalOpen(true);
    };

    const openDeleteSpamModal = (commentId) => {
        setSelectedCommentId(commentId);
        setIsDeleteModalOpen(true);
    };

    const renderTable = (columns, data, action) => (
        <div>
            {data && data.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            {columns.map((col, index) => (
                                <th key={index}>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                <td>{row.email || 'N/A'}</td>
                                <td>{row.webpageUrl || 'N/A'}</td>
                                <td>{row.content || 'N/A'}</td>
                                <td>{row.likes || 0}</td>
                                <td>{row.dislikes || 0}</td>
                                <td>
                                    <FaTrash className='delete-icon' onClick={() => action(row.id)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="no-data-message">No comments</div>
            )}
        </div>
    );
    const formatDate = (timestamp) => {
        if (timestamp && timestamp.toDate) {
            return timestamp.toDate().toLocaleString();
        }
        return 'N/A';
    };
    const renderSpamTable = (columns, data, action) => (
        <div>
            {data && data.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            {columns.map((col, index) => (
                                <th key={index}>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                <td>{row.name || 'N/A'}</td>
                                <td>{row.message}</td>
                                <td>{row.reason || 'N/A'}</td>
                                <td>{row.commentId || 'N/A'}</td>
                                <td>{row.reportedCount || 0}</td>
                                <td>
                                    <FaTrash className='delete-icon' onClick={() => action(row.commentId)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="no-data-message">No spam reported</div>
            )}
        </div>
    );

    return (
        <>
            <div className='summary-report-header'>
                <div className={`summary-tab ${view === 'commentReport' ? 'active' : ''}`} onClick={() => setView('commentReport')}>
                    Detailed report
                </div>
                <div className={`summary-tab ${view === 'spam' ? 'active' : ''}`} onClick={() => setView('spam')}>
                    Spam box
                </div>
                <div className={`summary-tab ${view === 'recent' ? 'active' : ''}`} onClick={() => setView('recent')}>
                    Recent
                </div>
            </div>

            <div className="detailed-report" hidden={view !== "commentReport"}>
                <div className='detail-report-container'>
                    <span className='detailed-report-text'>Detailed report</span>
                </div>
                {renderTable(
                    ['Email', 'Website', 'Content', 'Like', 'Dislike', 'Action'],
                    comments,
                    openDeleteModal
                )}
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={commentPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>

            <div className="detailed-report" hidden={view !== "spam"}>
                <div className='detail-report-container'>
                    <span className='detailed-report-text'>Spam Box</span>
                </div>
                {renderSpamTable(
                    ['Reported by', 'Content', 'Reason', 'Comment Id', 'Reported Count', 'Action'],
                    spamReports,
                    openDeleteSpamModal
                )}
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={spamPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>

            <div className='detailed-report' hidden={view !== "recent"}>
                <div className="recent-container-admin">
                    <InsightsList heading={'Popular Threads'} comments={popular} />
                    <InsightsList heading={"Lend your Insights"} comments={threads} />
                </div>
            </div>

            <DeleteModal
                isDeleteModalOpen={isDeleteModalOpen}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                action={view === 'spam' ? handleSpamDelete : handleDelete}
            />
            <ToastContainer />
        </>
    );
};

export default Summary;
