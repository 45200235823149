import React, { useEffect } from 'react';
import { saveTokenToDatabase } from './repository';
import { useAuth } from '../context/AuthContext';
import { messaging } from '../config/firebase-config';
import { getToken } from 'firebase/messaging';

function RequestPermission() {
  const { user } = useAuth();

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID });
          if (user) {
            saveTokenToDatabase(user.id, token);
          }
        } else {
          console.error('Permission not granted for Notification');
        }
      } catch (error) {
        console.error('Error getting permission for notifications', error);
      }
    };

    requestPermission();
  }, [user]);

  return null;
}

export default RequestPermission;
