import axios from 'axios';
import { encryptKey } from './encryption';

const encryptedKey = encryptKey(process.env.REACT_APP_WEBCUSSO_CLIENT_ID);


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_WEBCUSSO_SERVER_BASE_PATH + '/api',
  headers: {
    'x-webcusso-key': encryptedKey,
  }
});

export default axiosInstance;
