import React from 'react'
import './Right.css';

export default function Right() {
    return (
        <div className="footer-bottom">
            <p>&copy; 2024 WebCusso. All rights reserved.</p>
        </div>
    )
}
