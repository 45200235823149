import React, { useState, useEffect } from 'react';
import './Follow.css';
import axiosInstance from '../../common/axiosInstance';

function Follow({ url, initialFollowStatus, userId }) {
    const [following, setFollowing] = useState(initialFollowStatus);

    useEffect(() => {
        setFollowing(initialFollowStatus);
    }, [initialFollowStatus]);

    const handleFollowClick = async () => {
        const status = following ? 'unfollow' : 'follow';

        try {
            const response = await axiosInstance.post('/follow', {
                userId,
                url,
                status,
            });

            if (response.status === 200) {
                setFollowing(!following);
            } else {
                console.error('Failed to follow/unfollow');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className='follow-container'>
            <button
                className={`follow-button ${following ? 'following' : ''}`}
                onClick={handleFollowClick}
            >
                {following ? 'Following' : 'Follow'}
            </button>
        </div>
    );
}

export default Follow;
