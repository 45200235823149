import React from 'react';
import './Footer.css';
import { FaTelegramPlane, FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn, FaTelegram } from 'react-icons/fa';
import logoImage from '../../assets/logo.jpg'; 
import { Fax } from '@mui/icons-material';
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <div className="footer-logo">
                        <img src={logoImage} alt="WebCusso Logo" className="footer-logo-image" /> 
                        <p className="footer-title">WebCusso</p>
                        <p>Join the disCussion. Share Ideas. Connect.</p>
                    </div>
                </div>
                <div className="footer-section">
                    <h3>Contact Us</h3>
                  <div>
                  <p className="footer-heading">General Inquiries:</p>
                    <p>contact@webcusso.io</p>
                    <p>webcusso@gmail.com</p>
                  </div>
                    <br></br>
                    <div >
                        {/* <p className="footer-heading">Reach CEO at:</p> */}

                        {/* <p>Ashiq Rafa, CEO</p> */}
                        <p>WebCusso,</p>
                        <p>42, Duke</p>
                        <p>Triq IZ Zaghrun</p>
                        <p>Zabbar, Malta</p>
                        <p>ZBR 2261</p>
                    </div>
                </div>
                <div className="footer-section">
                    <h3>Quick Links</h3>
                    <p><a href="https://www.webcusso.io/terms-conditions">Terms & Conditions</a></p>
                    <p><a href="https://www.webcusso.io/privacy-policy">Privacy Policy</a></p>
                </div>
                <div className="footer-section">
                    <h3>Follow</h3>
                    <p>Stay updated with the latest developments in web engagement and online discourse.</p>
                    <form className="subscribe-form">
                        <label>Email *</label>
                        <div className="subscribe-input-container">
                            <input type="email" placeholder="Enter your email" />
                            <button type="submit">Subscribe</button>
                        </div>
                    </form>
                    <div className="social-media-icons">
                        <a href="https://t.me/WebCusso"><FaTelegramPlane /></a>
                        <a href="https://x.com/WebCusso">X</a>
                        {/* <a href="https://instagram.com"><FaInstagram /></a> */}
                        {/* <a href="https://facebook.com"><FaFacebookF /></a> */}
                        {/* <a href="https://linkedin.com"><FaLinkedinIn /></a> */}
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
