import React, { useEffect, useState } from 'react';
import './Operations.css';
import axiosInstance from '../../common/axiosInstance';
import { toast } from 'react-toastify';
import { Delete } from '@mui/icons-material';

function Operations({ secondary }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [domains, setDomains] = useState([]);
  const [newDomain, setNewDomain] = useState('');
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const [isSecondary, setIsSecondary] = useState(false); // State for Secondary ID checkbox

  // Fetch domains when the modal is opened
  useEffect(() => {
    if (isModalOpen) {
      fetchDomains();
    }
  }, [isModalOpen]);

  const fetchDomains = async () => {
    try {
      const { data, status } = await axiosInstance.get(secondary ? '/secondary' : '/whitelist');
      if (status === 200) {
        setDomains(data);
      } else {
        toast.error('Failed to fetch domains.');
      }
    } catch (error) {
      console.error('Error fetching domains:', error);
      toast.error('An error occurred while fetching domains.');
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const addDomain = async (e) => {
    e.preventDefault();
    if (!newDomain.trim()) {
      toast.error('Domain cannot be empty.');
      return;
    }
  
    try {
      const requestData = {
        domain: newDomain,
      };
  
      // Conditionally add fields to the request payload
      if (secondary) {
        requestData.isSecondary = true;
      } else {
        requestData.isChecked = isChecked;
      }
  
      const { status } = await axiosInstance.post(secondary ? '/secondary/add' : '/whitelist/add', requestData);
      
      if (status === 200) {
        let newDomainObject;
        // Manually create the new domain object
        if (secondary) {
          newDomainObject = {
            id: new Date().getTime(),
            domain: newDomain,
            isSecondary: true,
          };
        } else {
          newDomainObject = {
            id: new Date().getTime(), // Generate a temporary unique ID for the new domain
            domain: newDomain,        // The new domain entered by the user
            isChecked: isChecked,     // Use the current checkbox state
          };
        }
  
        setDomains((prev) => [...prev, newDomainObject]);
  
        setNewDomain('');
        setIsChecked(false);
        setIsSecondary(false);
        toast.success('Domain added successfully!');
      } else {
        toast.error('Failed to add domain.');
      }
    } catch (error) {
      console.error('Error adding domain:', error);
      toast.error('An error occurred while adding the domain.');
    }
  };
  

  const handleDeleteDomain = async (domainId) => {
    try {
      const { status } = await axiosInstance.delete(secondary ? `/secondary/domain/delete/${domainId}` : `/whitelist/delete/${domainId}`);
      if (status === 200) {
        setDomains((prev) => prev.filter((domain) => domain.id !== domainId));
        toast.success('Domain deleted successfully!');
      } else {
        toast.error('Failed to delete domain.');
      }
    } catch (error) {
      console.error('Error deleting domain:', error);
      toast.error('An error occurred while deleting the domain.');
    }
  };

  return (
    <div>
      {
        secondary ? <button className="operations-button" onClick={handleOpenModal} >
          Secondary
        </button> : <button className="operations-button" onClick={handleOpenModal}>
          Operations
        </button>
      }

      {isModalOpen && (
        <div className="operations-modal-overlay" onClick={handleCloseModal}>
          <div className="operations-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="operations-close-button" onClick={handleCloseModal}>
              ×
            </button>
           {secondary ?  <p className="detailed-report-text">Secondary Domains</p> :  <p className="detailed-report-text">Manage Domains</p>}

            <form className="input-section" onSubmit={addDomain}>
              <input
                type="text"
                placeholder="Enter Domain"
                value={newDomain}
                onChange={(e) => setNewDomain(e.target.value)}
                className="domain-input"
              />
              <button type="submit" className="add-button">
                Add
              </button>
            </form>

            {!secondary ? <div className="checkbox-section">
              <input
                type="checkbox"
                id="checkbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label htmlFor="checkbox"> Enable URL</label>
            </div> : <div className="checkbox-section">
              <input
                type="checkbox"
                id="secondary-checkbox"
                checked={true}
                onChange={(e) => setIsSecondary(e.target.checked)}
              />
              <label htmlFor="secondary-checkbox"> Secondary ID</label>
            </div>
            }


            <div className="operations-table-container">
              <table className="operations-custom-table">
                <thead>
                  <tr>
                    <th>Domain</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {
                  secondary ? <tbody>
                    {domains.map((domain) => (
                      <tr key={domain.id}>
                        <td>{domain.domain}</td>
                        <td>
                          {domain.isSecondary
                            ? 'Secondary'

                              : 'Nil'}
                        </td>
                        <td>
                          <button
                            className="operations-delete-button"
                            onClick={() => handleDeleteDomain(domain.id)}
                            aria-label="Delete Domain"
                          >
                            <Delete style={{ color: 'red' }} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody> : <tbody>
                    {domains.map((domain) => (
                      <tr key={domain.id}>
                        <td>{domain.domain}</td>
                        <td>
                          {domain.isChecked
                            ? 'Considered'
                            : 'Whitelisted'}
                        </td>
                        <td>
                          <button
                            className="operations-delete-button"
                            onClick={() => handleDeleteDomain(domain.id)}
                            aria-label="Delete Domain"
                          >
                            <Delete style={{ color: 'red' }} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Operations;
