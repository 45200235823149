import React, { useEffect, useState, useRef } from 'react';
import RequestPermission from '../../common/RequestPermission';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { useAuth } from '../../context/AuthContext';
import NotificationHandler from '../../common/NotificationHandler';
import notificationImage from '../../assets/notification.png';
import { FaEllipsisH, FaCheck, FaTimes } from 'react-icons/fa';
import './Notification.css';
import { formatTimeAgo } from '../../common/helper';
import { markAsRead } from '../../common/repository';
import spam from '../../assets/spam.png'

function Notification() {
    const [view, setView] = useState('all');
    const [menuOpen, setMenuOpen] = useState(null);
    const menuRef = useRef(null);
    const { notifications } = useAuth() ;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const deleteNotification = async (id) => {
        try {
            const notificationRef = doc(db, 'notifications', id);
            await deleteDoc(notificationRef);
        } catch (error) {
            console.error('Error deleting notification: ', error);
        }
    };

    const handleMenuClick = (id) => {
        setMenuOpen(menuOpen === id ? null : id);
    };

    const openCommentInNewWindow = (commentId , notificationId) => {
        markAsRead(notificationId);
        const newWindow = window.open(`/thread?url=${commentId}`, '_blank', 'noopener,noreferrer');
        if (newWindow) {
            newWindow.opener = null;
        }
    };
    const displayedNotifications = view === 'all' ? notifications : notifications.filter(notif => !notif.read);

    return (
        <>
            <h1 className="notification-title">Notifications</h1>
            <div className="notification-page">
                <div className='notification-container'>
                    <RequestPermission />
                    <div className='notification-header-main'>
                        <div className={`tab ${view === 'all' ? 'active' : ''}`} onClick={() => setView('all')}>
                            All ({notifications?.length || 0})
                        </div>
                        <div className={`tab ${view === 'unread' ? 'active' : ''}`} onClick={() => setView('unread')}>
                            Unread ({notifications?.filter(notif => !notif.read).length || 0})
                        </div>
                    </div>
                    <div className='notification-list'>
                        {displayedNotifications.length === 0 ? (
                            <div className="no-notifications">
                                <img src={notificationImage} alt="No notifications" className="no-notifications-image" />
                                <p>You don't have any notifications</p>
                            </div>
                        ) : (
                            displayedNotifications.map(notification => (
                                <div key={notification.id} className={`notification-item ${!notification.read ? 'unread' : ''}`}>
                                    <img src={notification.actionUserId !== 'admin' 
                                    ?notification.profileUrl : spam} alt="Notification" className="notification-image" />
                                    <div className="notification-content">
                                        <div className="notification-text">
                                            <p className="notification-message" onClick={() => openCommentInNewWindow(notification.webpageUrl,notification.id)} ><strong>{notification.senderName}</strong> {notification.message}</p>
                                            <p className="notification-time">{formatTimeAgo(notification.timestamp)}</p>
                                        </div>
                                        <div className="notification-menu">
                                            <FaEllipsisH onClick={() => handleMenuClick(notification.id)} />
                                            {menuOpen === notification.id && (
                                                <div className="notification-actions" ref={menuRef}>
                                                    <button onClick={() => { markAsRead(notification.id); handleMenuClick(notification.id); }}>
                                                        <FaCheck /> Mark as Read
                                                    </button>
                                                    <button onClick={() => { deleteNotification(notification.id); handleMenuClick(notification.id); }}>
                                                        <FaTimes /> Remove this notification
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    <NotificationHandler />
                </div>
            </div>
        </>
    );
}

export default Notification;
