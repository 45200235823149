import { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, startAfter, getDocs, getCountFromServer, doc, deleteDoc, where } from 'firebase/firestore';
import { db } from '../config/firebase-config';

const useSpamPaginatedQuery = (collectionName, itemsPerPage) => {
  const [spamReports, setSpamReports] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const fetchSpamReports = async (page = 0) => {
    setLoading(true);
    let q = query(
      collection(db, collectionName),
      orderBy('createdAt'),
      limit(itemsPerPage)
    );

    if (page > 0 && lastVisible) {
      q = query(
        collection(db, collectionName),
        orderBy('reportedAt'),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    }

    const querySnapshot = await getDocs(q);
    
    const spamReportsArray = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));



    const commentCountMap = spamReportsArray.reduce((acc, report) => {
      acc[report.commentId] = (acc[report.commentId] || 0) + 1;
      return acc;
    }, {});

    const groupedSpamReports = Object.keys(commentCountMap).map(commentId => ({
      commentId,
      reportedCount: commentCountMap[commentId],
      ...spamReportsArray.find(report => report.commentId === commentId),
    }));

    setSpamReports(groupedSpamReports);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoading(false);

    if (page === 0) {
      const totalQuery = await getCountFromServer(collection(db, collectionName));
      setPageCount(Math.ceil(totalQuery.data().count / itemsPerPage));
    }
  };


  const deleteSpamReport = async (commentId) => {
    const q = query(collection(db, collectionName), where('commentId', '==', commentId));
    const querySnapshot = await getDocs(q);

    const deletePromises = querySnapshot.docs.map(docSnapshot => deleteDoc(doc(db, collectionName, docSnapshot.id)));

    await Promise.all(deletePromises);
    fetchSpamReports();
  };


  useEffect(() => {
    fetchSpamReports();
  }, []);

  return { spamReports, loading, pageCount, fetchSpamReports,deleteSpamReport };
};

export default useSpamPaginatedQuery;
