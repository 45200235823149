import { useEffect } from 'react';
import { onMessage } from 'firebase/messaging';
import { messaging } from '../config/firebase-config';

const NotificationHandler = () => {
    useEffect(() => {
        const unsubscribe = onMessage(messaging, (payload) => {
            // Customize notification handling here
            // alert(`Notification: ${payload.notification.title} - ${payload.notification.body}`);
        });

        return unsubscribe;
    }, []);

    return null;
};

export default NotificationHandler;
