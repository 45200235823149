import React, { memo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaEnvelope, FaBell, FaBars, FaTimes} from 'react-icons/fa';
import { BiMerge  } from "react-icons/bi";
import { MdDashboard } from 'react-icons/md';
import './Navbar.css';
import { useAuth } from '../../context/AuthContext';
import { handleButtonClick } from '../../common/helper';
import logo from '../../assets/logo-clean.png';

const Navbar = memo(() => {
  const [isOpen, setIsOpen] = useState(false);

  const { user, logout, notificationCount } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  const handleLogout = async () => {
    await logout();
    toggleMenu();
    localStorage.clear();
    navigate('/login');
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
      <img src={logo} alt="logo" className="notification-image" />
      {/* <div className='logo-title'>webCusso</div> */}
      </div>
      {user && (
        <>
          <div className="navbar-hamburger" onClick={toggleMenu}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
            {user.role === 'admin' ? (
              <li>
                <Link to="/dashboard" onClick={toggleMenu}>
                  <MdDashboard /> Dashboard
                </Link>
              </li>
            ) : (
              <>
                <li>
                  <Link to="/home" onClick={toggleMenu}>
                    <FaEnvelope /> Home
                  </Link>
                </li>
                <li>
                  <Link to="/notifications" onClick={toggleMenu}>
                    <FaBell /> Notifications
                    {notificationCount > 0 && (
                      <span className="notification-badge">{notificationCount}</span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="/profile" onClick={toggleMenu}>
                    <FaUser /> Profile
                  </Link>
                </li>
                <li>
                  <Link  onClick={() => handleButtonClick('https://chromewebstore.google.com/detail/webcusso/cngoehamkmpnmokpbfeoamdcifobfkno?authuser=0&hl=en&pli=1')}>
                  <BiMerge  /> Get started
                  </Link>
                </li>
              </>
            )}
            <button className="navbar-button mobile-only" onClick={handleLogout}>
              Log Out
            </button>

          </ul>
          <button className="navbar-button desktop-only" onClick={handleLogout}>
            Log Out
          </button>
        
        </>
      )}
    </nav>
  );
});

export default Navbar;
