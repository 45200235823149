import React from 'react';
import { BeatLoader } from 'react-spinners';
import './LoadingScreen.css';

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <BeatLoader color="#FEAF04" size={20} />
    </div>
  );
};

export default LoadingScreen;
