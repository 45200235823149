import React, { useState, useCallback } from 'react';
import icon from '../../assets/default-icon.jpg';
import './Comments.css';
import { formatDistanceToNow } from 'date-fns';
import { ThumbUp, ThumbUpOutlined, ThumbDown, ThumbDownOutlined, Reply, Delete, Flag } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { FaCheckCircle } from 'react-icons/fa';

const Comment = React.memo(({ comment, setActiveCommentId, onLike, onDislike, onDeleteClick, onReportSpamClick }) => {
  const [showReplies, setShowReplies] = useState(false);
  const { user } = useAuth();
  const isAdmin = user.role === 'admin';

  const toggleReplies = useCallback(() => setShowReplies(prev => !prev), []);

  const formatTimeAgo = (timestamp) => {
    try {
      const date = timestamp && timestamp._seconds ? new Date(timestamp._seconds * 1000) : new Date();
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      console.error(error);
      return "Invalid date";
    }
  };

  return (
    <div className="comment_item" style={{ backgroundColor: comment.color }}>
      <div className='comment_heading'>
        <img src={comment.profile ? comment.profile : icon} alt="profile" className="profile_pic" />
        <div className="comment_body">
          <div className="comment_header">
          <div>
          <a href={comment.x_url} target="_blank" rel="noopener noreferrer" className="comment_author">
            {comment.username}</a>
            {comment.verified ?  <FaCheckCircle className='verified-icon'/> : <span> </span>}
            <a href={comment.webpageUrl} target="_blank" rel="noopener noreferrer" className="comment-url">
            {comment.webpageUrl}</a>
          </div>
          <p className="comment_date">{formatTimeAgo(comment.createdAt)}</p>

          </div>
          <p className="comment_content">{comment.content}</p>
          <div className="comment_actions">
            <span className="like_button" onClick={() => onLike(comment.id, comment.parentId)}>
              <div className='like-dislike-number'>{comment.likes} </div>
              {comment.likedBy.includes(user.id) ? (
                <ThumbUp color="primary" fontSize="small" />
              ) : (
                <ThumbUpOutlined fontSize="small" />
              )}
            </span>
            <span className="dislike_button" onClick={() => onDislike(comment.id, comment.parentId)}>
              <div className='like-dislike-number'> {comment.dislikes} </div>
              {comment.dislikedBy.includes(user.id) ? (
                <ThumbDown color="error" fontSize="small" />
              ) : (
                <ThumbDownOutlined sx={{ color: 'red' }} fontSize="small" />
              )}
            </span>
            <span className="reply_button" onClick={() => setActiveCommentId(comment.parentId ? comment.parentId : comment.id)}>
              <Reply fontSize="small" />
            </span>
            {comment.replies && comment.replies.length > 0 && (
              <span className="reply_button" onClick={toggleReplies}>{showReplies ? 'Hide' : 'Show'}</span>
            )}
            <span className="delete_button" onClick={() => onDeleteClick(comment.id)} hidden={!isAdmin && comment.userId !== user.id}>
              <Delete sx={{ color: 'red' }} fontSize="small" />
            </span>
            <span className="spam_button" onClick={() => onReportSpamClick(comment)}>
              <Flag sx={{ color: 'red' }} fontSize="small" />
            </span>
          </div>
          {showReplies && (
            <div className="comment_replies">
              {comment.replies.map(reply => (
                <Comment key={reply.id} comment={reply} setActiveCommentId={setActiveCommentId} onLike={onLike} onDislike={onDislike} onDeleteClick={onDeleteClick} onReportSpamClick={onReportSpamClick} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Comment;
