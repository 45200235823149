import React from 'react';
import './DeleteModal.css';
import Modal from 'react-modal';

const DeleteModal = ({ isDeleteModalOpen, setIsDeleteModalOpen, action }) => {
    return (
        <Modal
            isOpen={isDeleteModalOpen}
            onRequestClose={() => setIsDeleteModalOpen(false)}
            className="modal"
            overlayClassName="overlay"
        >
            <div className="modal-header">
                <h2>Confirm Delete</h2>
                <button onClick={() => setIsDeleteModalOpen(false)}>&times;</button>
            </div>
            <div className="modal-content">
                <p>Are you sure you want to delete this comment?</p>
                <div className="modal-actions">
                    <button className="save-button" onClick={action}>Yes, delete</button>
                    <button className="cancel-button" onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
