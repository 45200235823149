import React, { useState, useEffect, useRef } from 'react';
import './FilterDropDown.css';
import { FcFilledFilter } from 'react-icons/fc';

const FilterDropDown = ({ onSelectFilter, filterType }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleFilterChange = (filter) => {
    onSelectFilter(filter);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <button className="custom-dropdown-button" onClick={toggleDropdown}>
        <FcFilledFilter />
      </button>
      {dropdownOpen && (
        <div className="custom-dropdown-content">
          <button
            onClick={() => handleFilterChange('recent')}
            disabled={filterType === 'recent'}
            style={{ backgroundColor: filterType === 'recent' ? '#FEAF04' : 'transparent' }}
          >
            Recent
          </button>
          <button
            onClick={() => handleFilterChange('liked')}
            disabled={filterType === 'liked'}
            style={{ backgroundColor: filterType === 'liked' ? '#FEAF04' : 'transparent' }}
          >
            Top liked
          </button>
          <button
            onClick={() => handleFilterChange('mixed')}
            disabled={filterType === 'mixed'}
            style={{ backgroundColor: filterType === 'mixed' ? '#FEAF04' : 'transparent' }}
          >
            Mixed
          </button>
        </div>
      )}
    </div>
  );
};

export default FilterDropDown;
