import React, { useState, useEffect } from 'react';
import './InsightsList.css';
import noContent from '../../assets/no-content.webp';
import defaultLogo from '../../assets/default-icon.jpg'; // Add a default logo image
import { openCommentInNewWindow } from '../../common/helper';

const InsightsList = ({ comments = [], heading = 'Insights' }) => {

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const createImageWithText = (text) => {
    try {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const size = 50;
      canvas.width = size;
      canvas.height = size;

      // Set background color
      context.fillStyle = getRandomColor();
      context.fillRect(0, 0, size, size);

      // Set text style
      context.font = '30px Arial';
      context.fillStyle = '#FFFFFF';
      context.textAlign = 'center';
      context.textBaseline = 'middle';

      // Draw text
      context.fillText(text, size / 2, size / 2);

      return canvas.toDataURL('image/png');
    } catch (error) {
      console.error('Error creating image with text:', error);
      return defaultLogo; // Fallback to default logo in case of error
    }
  };

  const createPlaceholderImage = (url) => {
    try {
      // Check if the URL is valid and not empty
      if (!url || typeof url !== 'string' || !url.startsWith('http')) {
        return defaultLogo; // Fallback to default logo if the URL is invalid
      }
  
      const parsedUrl = new URL(url);
      const hostname = parsedUrl.hostname.replace('www.', '');
      const domainParts = hostname.split('.');
      const domainName = domainParts[0] || 'N/A';
      const firstLetter = domainName.charAt(0).toUpperCase() || 'N';
  
      return createImageWithText(firstLetter);
    } catch (error) {
      console.error('Error parsing URL for placeholder image:', error);
      return defaultLogo; // Fallback to default logo in case of error
    }
  };
  

  const handleCommentClick = (url) => {
    if (url) {
      openCommentInNewWindow(url);
    } else {
      console.warn('Invalid URL provided for opening comment.');
    }
  };

  return (
    <div className="insights-list">
      <h2>{heading}</h2>
      <div className="insight-container">
        {comments.length > 0 ? (
          comments.map((item, index) => {
            const { url = '', count = 0, comments: itemComments = [] } = item;

            const hasComments = itemComments.length > 0;
            const firstComment = hasComments ? itemComments[0] : null;

            const imageSrc = firstComment?.websiteLogo
              ? firstComment.websiteLogo
              : createPlaceholderImage(url);

            const displayText = firstComment?.ticker
              ? firstComment.ticker
              : url;

            return (
              <div className="insight-item" key={index}>
                <img
                  src={imageSrc}
                  alt="Logo"
                  className="insight-logo"
                  onError={(e) => { e.target.src = defaultLogo; }}
                />
                <div className="insight-details">
                  <div className="insight-links">
                    <a
                      href={url || '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="insight-url"
                    >
                      {displayText}
                    </a>
                    <p
                      onClick={() => handleCommentClick(url)}
                      className="insight-short-url"
                    >
                      Click to view the comment
                    </p>
                  </div>
                  <div className="insight-comments">
                    {count} {count === 1 ? 'Comment' : 'Comments'}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-content">
            <img
              src={noContent}
              alt="No content available"
              className="no-content-image"
            />
            <p>No content available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightsList;
