import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axiosInstance from '../../common/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ClubModal.css';

function ClubModal({ url }) {
    const [isSpamModalOpen, setIsSpamModalOpen] = useState(false);
    const [sourceUrl, setSourceUrl] = useState('');
    const [destinationUrl, setDestinationUrl] = useState('');
    const [clubbedUrls, setClubbedUrls] = useState([]);

    useEffect(() => {
        if (isSpamModalOpen) {
            fetchClubbedUrls();
        }
    }, [isSpamModalOpen]);

    const fetchClubbedUrls = async () => {
        try {
            const { data, status } = await axiosInstance.get('/club/all');
            if (status === 200) {
                setClubbedUrls(data);
            } else {
                toast.error('Failed to fetch clubbed URLs.');
            }
        } catch (error) {
            console.error('Error fetching clubbed URLs:', error);
            toast.error('An error occurred while fetching clubbed URLs.');
        }
    };

    const openSpamModal = () => {
        setIsSpamModalOpen(true);
    };

    const handleClubUrl = async (e) => {
        e.preventDefault();

        if (!sourceUrl.trim() || !destinationUrl.trim()) {
            toast.error('Both fields are required.');
            return;
        }

        try {
            const response = await axiosInstance.post('/club/add', {
                source: sourceUrl, 
                destination: destinationUrl, 
            });

            if (response.status === 200) {
                toast.success('URLs clubbed successfully!'); 
                setSourceUrl(''); 
                setDestinationUrl('');
                fetchClubbedUrls(); 
            } else {
                toast.error('Failed to club URLs.'); 
            }
        } catch (error) {
            console.error('Error clubbing URLs:', error);
            toast.error('An error occurred while clubbing URLs.'); 
        }
    };

    return (
        <div>
            <button className="club-button" onClick={openSpamModal}>
                Club
            </button>
            <Modal
                isOpen={isSpamModalOpen}
                onRequestClose={() => setIsSpamModalOpen(false)}
                className="club-modal-content"
                overlayClassName="club-modal-overlay"
            >
                <div className="modal-header">
                    <h2>Club Threads</h2>
                    <button className="club-close-button" onClick={() => setIsSpamModalOpen(false)}>
                        &times;
                    </button>
                </div>
                <div className="modal-content">
                    <form className="club-input-section" onSubmit={handleClubUrl}>
                        <input
                            className="add-url-input"
                            type="text"
                            placeholder="Enter Source URL"
                            value={sourceUrl}
                            onChange={(e) => setSourceUrl(e.target.value)}
                        />
                        <input
                            className="add-url-input"
                            type="text"
                            placeholder="Enter Clubbed URL"
                            value={destinationUrl}
                            onChange={(e) => setDestinationUrl(e.target.value)}
                        />
                        <div className="modal-actions">
                            <button
                                type="submit"
                                className="club-save-button"
                                onClick={handleClubUrl}
                                disabled={!sourceUrl.trim() || !destinationUrl.trim()}
                            >
                                Add
                            </button>
                        </div>
                    </form>

                    <div className="clubbed-urls-table-container">
                        <table className="clubbed-urls-custom-table">
                            <thead>
                                <tr>
                                    <th>URL</th>
                                    <th>Clubbed URL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clubbedUrls.map((club, index) => (
                                    <tr key={index}>
                                        <td>{club.webpageUrl}</td>
                                        <td>
                                            {
                                              club.clubbedUrl &&  club.clubbedUrl.map((url, idx) => (
                                                    <div key={idx}>{url}</div>
                                                ))
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
            <ToastContainer />
        </div>
    );
}

export default ClubModal;
