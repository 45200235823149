import { collection, doc, serverTimestamp, addDoc, updateDoc, getCountFromServer, query, getDocs, where, getDoc, setDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import axiosInstance from './axiosInstance';

// Add likes and dislikes
export const addLikeDislike = async (comment, isLike, commentId, uid) => {
  const commentRef = doc(db, 'comments', commentId);

  if (isLike) {
    if (comment.likedBy.includes(uid)) {
      // User has already liked, so remove the like
      await updateDoc(commentRef, {
        likes: comment.likes - 1,
        likedBy: arrayRemove(uid),
        lastActionUserId: uid
      });
    } else {
      // User has not liked, so add the like and remove dislike if any
      await updateDoc(commentRef, {
        likes: comment.likes + 1,
        likedBy: arrayUnion(uid),
        lastActionUserId: uid
      });
    }
  } else {
    if (comment.dislikedBy.includes(uid)) {
      // User has already disliked, so remove the dislike
      await updateDoc(commentRef, {
        dislikes: comment.dislikes - 1,
        dislikedBy: arrayRemove(uid),
        lastActionUserId: uid

      });
    } else {
      // User has not disliked, so add the dislike and remove like if any
      await updateDoc(commentRef, {
        dislikes: comment.dislikes + 1,
        dislikedBy: arrayUnion(uid),
        lastActionUserId: uid
      });
    }
  }
};
// Add comments to Firebase
// export const handlePostComment = async (comment) => {
//   try {
//     await addDoc(collection(db, 'comments'), {
//       author: comment.author,
//       content: comment.content,
//       dislikes: comment.dislikes,
//       likes: comment.likes,
//       parentId: comment.parentId,
//       userId: comment.userId,
//       webpageUrl: comment.webpageUrl,
//       email: comment.email,
//       createdAt: serverTimestamp(),
//       likedBy: [],
//       dislikedBy: []
//     });
//   } catch (error) {
//     console.error("Error posting comment:", error);
//   }
// };

// Add comments to Firebase
export const handlePostComment = async (comment) => {
  try {
    await axiosInstance.post('/postComment', comment);
  } catch (error) {
    console.error("Failed to post comment:", error);
    throw error;
  };
}

export const getOverViewCount = async (collectionName) => {
  try {
    const usersCollection = collection(db, collectionName);
    const snapshot = await getCountFromServer(usersCollection);
    return snapshot.data().count;
  } catch (error) {
    console.error("Error getting overview count:", error);
  }
}


export const getThreadCount = async (collectionName) => {
  // Fetch comments where parentId is null
  try {
    const q = query(collection(db, collectionName), where('parentId', '==', null));
    const querySnapshot = await getDocs(q);

    // Extract unique websiteUrls
    const websiteUrls = new Set();
    querySnapshot.forEach((doc) => {
      websiteUrls.add(doc.data().webpageUrl);
    });

    return websiteUrls.size;
  } catch (error) {
    console.error("Error  getThreadCount :", error);

  }

}


export const fetchProfile = async (uid) => {
  const userDoc = doc(db, 'users', uid);
  const userSnap = await getDoc(userDoc);
  if (userSnap.exists()) {
    return userSnap.data();
  }
};



export const saveTokenToDatabase = async (userId, token) => {
  if (!token) return;

  const tokenRef = doc(db, 'users', userId);
  const docSnap = await getDoc(tokenRef);

  if (docSnap.exists()) {
    await updateDoc(tokenRef, {
      fcmTokens: arrayUnion(token),
    });
  } else {
    await setDoc(tokenRef, {
      fcmTokens: [token],
    });
  }
};

export const saveUser = async (user) => {
  if (user) {
    const userRef = doc(db, 'users', user.id);
    await setDoc(userRef, {
      email: user.email,
      profilePicture: user.photoURL,
      name: user.displayName,
      fcmTokens: [],
      address: "",
      dateOfBirth: "",
      phone: "",
      role: "user",
      createdAt: serverTimestamp(),
    }, { merge: true });
  }
};



export const addUser = async (user) => {

  const userData = {
    picture: user.photoURL,
    name: user.displayName,
    email: user.email
  };

  try {
    return await axiosInstance.post("/addUser", userData);
  } catch (error) {
    console.error('Error adding user:', error);
  }
};


export const deleteCommentById = async (commentId) => {
  try {
    await axiosInstance.delete(`/comments/delete/${commentId}`);

  } catch (error) {
    console.error('Error deleting comment:', error);
  }
}

export const markAsRead = async (id) => {
  try {
      const notificationRef = doc(db, 'notifications', id);
      await updateDoc(notificationRef, { read: true });
  } catch (error) {
      console.error('Error marking as read: ', error);
  }
};