import React, { useEffect, useState } from 'react';
import { FaPencilAlt, FaPlusCircle, FaCheckCircle } from 'react-icons/fa';
import { doc, updateDoc, getDocs, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../config/firebase-config';
import { useAuth } from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';
import './Profile.css';
import { fetchProfile } from '../../common/repository';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useAuth();
  const [profile, setProfile] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [initialProfile, setInitialProfile] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadProfile = async () => {
      const cachedProfile = localStorage.getItem('profile');
      if (cachedProfile) {
        const profileData = JSON.parse(cachedProfile);
        setProfile(profileData);
        setInitialProfile(profileData);
      } else {
        const data = await fetchProfile(user.id);
        setProfile(data);
        setInitialProfile(data);
        localStorage.setItem('profile', JSON.stringify(data));
      }
    };

    if (user) {
      loadProfile();
    }
  }, [user]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const profileUrl = params.get('profileUrl');

    if (profileUrl && profileUrl.includes('twitter.com')) {
      if (!profile.verified || profile.x_url !== profileUrl) {
        updateProfileWithTwitterInfo(profileUrl);
      }
    }
  }, [profile]);

  const updateProfileWithTwitterInfo = async (profileUrl) => {
    try {
      const userDoc = doc(db, 'users', user.id);
      await updateDoc(userDoc, {
        x_url: profileUrl,
        verified: true,
      });
      const updatedProfile = { ...profile, x_url: profileUrl, verified: true };
      setProfile(updatedProfile);
      localStorage.setItem('profile', JSON.stringify(updatedProfile));
      navigate('/profile');
    } catch (error) {
      toast.error('Failed to verify Twitter account.');
    }
  };

  const validateProfile = async () => {
    const newErrors = {};
    const currentDate = new Date().toISOString().split('T')[0];

    if (profile.phone && !/^\d{10}$/.test(profile.phone)) {
      newErrors.phone = 'Phone number must be 10 digits';
    }
    if (profile.address && profile.address.length > 100) {
      newErrors.address = 'Address cannot exceed 100 characters';
    }
    if (profile.dateOfBirth && profile.dateOfBirth > currentDate) {
      newErrors.dateOfBirth = 'Date of birth cannot be in the future';
    }
    if (profile.username && !(await isUsernameUnique(profile.username))) {
      newErrors.username = 'Username is already taken';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isUsernameUnique = async (username) => {
    const querySnapshot = await getDocs(collection(db, 'users'));
    let isUnique = true;
    querySnapshot.forEach((doc) => {
      if (doc.data().username === username && doc.id !== user.id) {
        isUnique = false;
      }
    });
    return isUnique;
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    if (!(await validateProfile())) return;
  
    try {
      if (imageFile) {
        const existingProfilePicture = profile.profilePicture;
  
        if (existingProfilePicture) {
          const storageRef = ref(storage, `profilePictures/${user.id}`);
          const fileRef = ref(storage, storageRef);
          await deleteObject(fileRef).catch((error) => {
            console.error('Error removing existing profile picture:', error);
          });
        }
  
        const storageRef = ref(storage, `profilePictures/${user.id}`);
        await uploadBytes(storageRef, imageFile);
        const downloadURL = await getDownloadURL(storageRef);
        profile.profilePicture = downloadURL;
      }
  
      const userDoc = doc(db, 'users', user.id);
      await updateDoc(userDoc, profile);
      localStorage.setItem('profile', JSON.stringify(profile));
      setInitialProfile(profile);
      toast.success('Profile details updated!');
      setIsChanged(false);
      setIsEditing(false);
    } catch (error) {
      toast.error('Failed to update profile.');
    }
  };
  
  const handleCancelClick = () => {
    setProfile(initialProfile);
    setIsEditing(false);
    setErrors({});
    setIsChanged(false);
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 5000000 && ['image/jpeg', 'image/png'].includes(file.type)) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile((prevProfile) => ({
          ...prevProfile,
          profilePicture: reader.result,
        }));
      };
      reader.readAsDataURL(file);
      setIsChanged(true);
    } else {
      setErrors({
        ...errors,
        profilePicture: file.size > 5000000 ? 'File must be less than 5MB' : 'File must be JPEG or PNG',
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
    setIsChanged(true);
    if (name === 'username') {
      validateUsername(value);
    }
  };

  const validateUsername = async (username) => {
    if (!(await isUsernameUnique(username))) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: 'Username is already taken',
      }));
    } else {
      setErrors((prevErrors) => {
        const { username, ...otherErrors } = prevErrors;
        return otherErrors;
      });
    }
  };


  const verifyWithTwitter = () => {
    const url = process.env.REACT_APP_WEBCUSSO_SERVER_BASE_PATH + '/api/auth/twitter';
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="profile-section">
      <div className="profile-header">
        <div className="profile-info">
          <div className="profile-pic-container">
            <img src={profile.profilePicture} loading="lazy" alt="Profile" className="profile-pic" />
          </div>
          <div className="profile-details">
            <h2>{user.username} {profile.verified && <FaCheckCircle className="verified-icon" />}</h2>
            <p>{user.name}</p>
          </div>
        </div>
      </div>
      <div className="profile-content">
        <div className="section general-info">
          <div className="section-header">
            <div className="section-general-icon">
              <div className="icon-container">
                <FaPencilAlt className="section-icon" />
              </div>
              <h3>General Information</h3>
            </div>
            <button className="edit-button" onClick={handleEditClick}>
              <FaPencilAlt /> Edit
            </button>
          </div>
          <table className="info-table">
            <tbody>
              <tr>
                <td className="label">Email</td>
                <td className="value">{user.email}</td>
              </tr>
              {/* <tr>
                <td className="label">Phone</td>
                <td className="value">{profile.phone}</td>
              </tr> */}
              {/* <tr>
                <td className="label">Bio</td>
                <td className="value">{profile.address}</td>
              </tr> */}
              {/* <tr>
                <td className="label">Date of birth</td>
                <td className="value">{formatDate(profile.dateOfBirth)}</td>
              </tr> */}
              {profile.verified !== true &&    <tr>
                <td className="label">X</td>
                <td className="value">
                   <button className='twitter-button' onClick={verifyWithTwitter}>Verify Twitter Account</button>
                </td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
      <Modal
        isOpen={isEditing}
        onRequestClose={handleCancelClick}
        contentLabel="Edit Profile"
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-header">
          <h2>My profile</h2>
          <button onClick={handleCancelClick} className="close-button">&times;</button>
        </div>
        <form>
          <div className="modal-content">
            <div className="profile-pic-container" style={{ textAlign: 'center' }}>
              <img src={profile.profilePicture} loading="lazy" alt="Profile" className="profile-pic" />
              <div className="edit-profile-pic">
                <label htmlFor="profile-pic-upload">
                  <FaPlusCircle className="edit-icon" />
                </label>
                <input
                  id="profile-pic-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleProfilePicChange}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                name="username"
                value={profile.username || ''}
                onChange={handleInputChange}
                className="edit-input"
              />
              {errors.username && <div className="error-text">{errors.username}</div>}
            </div>
            {/* <div className="input-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                name="phone"
                value={profile.phone || ''}
                maxLength="10"
                onChange={handleInputChange}
                className="edit-input"
              />
              {errors.phone && <div className="error-text">{errors.phone}</div>}
            </div> */}
            {/* <div className="input-group">
              <label htmlFor="address">Bio</label>
              <textarea
                name="address"
                value={profile.address || ''}
                maxLength="100"
                onChange={handleInputChange}
                className="edit-input"
              />
              {errors.address && <div className="error-text">{errors.address}</div>}
            </div> */}
            {/* <div className="input-group">
              <label htmlFor="dateOfBirth">Date of Birth</label>
              <input
                type="date"
                name="dateOfBirth"
                value={formatDate(profile.dateOfBirth) || ''}
                onChange={handleInputChange}
                className="edit-input"
                max={new Date().toISOString().split('T')[0]}
              />
              {errors.dateOfBirth && <div className="error-text">{errors.dateOfBirth}</div>}
            </div> */}
          </div>
          <div className="modal-actions">
            <button type="button" className="save-button" onClick={handleSaveClick} disabled={!isChanged}>
              Save
            </button>
            <button type="button" className="cancel-button" onClick={handleCancelClick}>
              Close
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Profile;
