// src/common/AdminRoute.js
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ClipLoader } from 'react-spinners';

const AdminRoute = ({ element: Component, ...rest }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <ClipLoader color="#3498db" size={50} />;
  }

  return user && user.role === 'admin' ? <Navigate to= "/home" /> : <Navigate to="/login" />;
};

export default AdminRoute;
