import React, { useMemo } from 'react';
import './Overview.css';
import { FaComments, FaEnvelope, FaUser } from 'react-icons/fa';

const Overview = ({ thread, comments, user }) => {
  const memoizedValues = useMemo(() => ({ thread, comments, user }), [thread, comments, user]);

  return (
    <>
      <div className='detail-report-container'>
        <span className='detailed-report-text'>Overview</span>
      </div>
      <div className="overview">
        <div className="card" style={{ backgroundColor: '#EBFDFF' }}>
          <div className='card-heading'>
            <p className='card-heading-text'>Users</p>
            <p className='card-heading-icon-user'><FaUser /></p>
          </div>
          <p className='card-content' style={{ color: '#379AE6' }}>{memoizedValues.user}</p>
          <span><span className='card-percentage'>{memoizedValues.user}</span> number of active user</span>
        </div>
        <div className="card" style={{ backgroundColor: '#F1F4FD' }}>
          <div className='card-heading'>
            <p className='card-heading-text'>Threads</p>
            <p className='card-heading-icon-thread'><FaEnvelope /></p>
          </div>
          <p className='card-content' style={{ color: '#4069E5' }}>{memoizedValues.thread}</p>
          <span><span className='card-percentage'>{memoizedValues.thread} </span>number of active threads</span>
        </div>
        <div className="card" style={{ backgroundColor: '#FDF2F2' }}>
          <div className='card-heading'>
            <p className='card-heading-text'>Comments</p>
            <p className='card-heading-icon-comments'><FaComments /></p>
          </div>
          <p className='card-content' style={{ color: '#E05858' }}>{memoizedValues.comments}</p>
          <span><span className='card-percentage'>{memoizedValues.comments}</span> total number of comments</span>
        </div>
      </div>
    </>
  );
};

export default Overview;
